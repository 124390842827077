import { inject, Injectable } from '@angular/core';
import { FeedbackRepoModel } from '../models/feedback-repo.model';

import { HttpClient } from '@angular/common/http';
import { PagingResponse } from '@root/app/shared/model/page-response.model';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import {
  FeedbackDataModel,
  FeedbackDtoDataModel,
} from '../models/feedback-data.model';
import { FeedbackListParams } from '../models/feedback-params.model';

@Injectable({ providedIn: 'root' })
export class FeedbackRepositoryService implements FeedbackRepoModel {
  private http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUrl}/v2/Feedbacks`;

  createFeedback(content: FeedbackDtoDataModel): Observable<string> {
    return this.http.post<string>(this.baseUrl, content);
  }

  getFeedbackList(
    _params: FeedbackListParams
  ): Observable<PagingResponse<FeedbackDataModel>> {
    return this.http.post<PagingResponse<FeedbackDataModel>>(
      `${this.baseUrl}/Search`,
      _params
    );
  }

  getById(id: number): Observable<FeedbackDataModel> {
    return this.http.get<FeedbackDataModel>(`${this.baseUrl}/${id}`);
  }

  changeStatus(id: number, status: string): Observable<string> {
    return this.http.patch<string>(`${this.baseUrl}/${id}`, {
      status: status,
    });
  }
}
