import { inject, Injectable } from '@angular/core';
import { FeedbackRepositoryService } from './feedback-repo.service';
import {
  FeedbackDtoPresenterModel,
  FeedbackPresenterModel,
} from '../models/feedback-presenter.model';
import { map, Observable } from 'rxjs';
import {
  getFeedbackDTODataModel,
  getFeedbackPresenterModel,
} from '../parsers/feedback.parser';
import { KeyDescription } from '@root/app/shared/model/key-description';
import { SystemSettingsService } from '@root/app/shared/services/system-settings.service';
import { DropDownType } from '@root/app/shared/enums/drop-down-type.enum';
import { FeedbackListParams } from '../models/feedback-params.model';
import { PagingResponse } from '@root/app/shared/model/page-response.model';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private dataService = inject(FeedbackRepositoryService);
  private systemSettingsService = inject(SystemSettingsService);

  createFeedback(content: FeedbackDtoPresenterModel): Observable<string> {
    return this.dataService.createFeedback(getFeedbackDTODataModel(content));
  }

  getFeedbackList(
    _params: FeedbackListParams
  ): Observable<PagingResponse<FeedbackPresenterModel>> {
    return this.dataService.getFeedbackList(_params).pipe(
      map((data) => {
        return {
          ...data,
          list: data.list.map((item) => getFeedbackPresenterModel(item)),
        };
      })
    );
  }

  getFeedbackTypes(): Observable<KeyDescription[]> {
    return this.systemSettingsService
      .getDropDownListByType(DropDownType.FeedbackTypes)
      .pipe(
        map((types) =>
          types.dropDownItems.map((type) => {
            return {
              key: type.key,
              description: type.description,
            };
          })
        )
      );
  }

  getById(id: number): Observable<FeedbackPresenterModel> {
    return this.dataService
      .getById(id)
      .pipe(map((item) => getFeedbackPresenterModel(item)));
  }

  getFeedbackStatus(): Observable<KeyDescription[]> {
    return this.systemSettingsService
      .getDropDownListByType(DropDownType.FeedbackStatuses)
      .pipe(
        map((types) =>
          types.dropDownItems.map((type) => {
            return {
              key: type.key,
              description: type.description,
            };
          })
        )
      );
  }

  changeStatus(id: number, status: string): Observable<string> {
    return this.dataService.changeStatus(id, status);
  }
}
