import {
  FeedbackDataModel,
  FeedbackDtoDataModel,
} from '../models/feedback-data.model';
import {
  FeedbackDtoPresenterModel,
  FeedbackPresenterModel,
} from '../models/feedback-presenter.model';

export function getFeedbackDTODataModel(
  presenter: FeedbackDtoPresenterModel
): FeedbackDtoDataModel {
  return { ...presenter };
}

export function getFeedbackPresenterModel(
  model: FeedbackDataModel
): FeedbackPresenterModel {
  return {
    ...model,
    _trimmedNotes:
      model.notes?.length > 30
        ? model.notes.substring(0, 19) + '...'
        : model.notes,
  };
}
